import { render, staticRenderFns } from "./printBillNew.vue?vue&type=template&id=c89b9498&scoped=true"
import script from "./printBillNew.vue?vue&type=script&lang=js"
export * from "./printBillNew.vue?vue&type=script&lang=js"
import style0 from "./printBillNew.vue?vue&type=style&index=0&id=c89b9498&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c89b9498",
  null
  
)

export default component.exports